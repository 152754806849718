import request from '@/utils/request'
const isProd = process.env.NODE_ENV === 'production'


const noticeApi = {
	getTsNoticeType: (isProd ? '' : '/api') + '/newAdmin.php?c=TsNotice&a=getTsNoticeType',
	addNotice: (isProd ? '' : '/api') + '/newAdmin.php?c=TsNotice&a=addTsNotice',
	getTsList: (isProd ? '' : '/api') + '/newAdmin.php?c=TsNotice&a=getTsNoticeList',
	delNotice: (isProd ? '' : '/api') + '/newAdmin.php?c=TsNotice&a=delTsNotice',
	getWhiteList: (isProd ? '' : '/api') + '/newAdmin.php?c=TsNotice&a=getWhiteList',
	getKey: (isProd ? '' : '/api') + '/newAdmin.php?c=TsNotice&a=getWlkey',
	delName: (isProd ? '' : '/api') + '/newAdmin.php?c=TsNotice&a=delWhiteList',
	//推送
	sendMsg: (isProd ? '' : '/api') + '/newAdmin.php?c=Tsnotice&a=push_notice',
	getData: (isProd ? '' : '/api') + '/newAdmin.php?c=tsnotice&a=push_info',
	
	//服务通知
	getServiceList: (isProd ? '' : '/api') + '/newAdmin.php?c=Fwnotice&a=getFwNoticeList',
	addServiceNotice: (isProd ? '' : '/api') + '/newAdmin.php?c=Fwnotice&a=addFwNotice',
	sendServiceMsg: (isProd ? '' : '/api') + '/newAdmin.php?c=Fwnotice&a=push_notice', 
	delSend: (isProd ? '' : '/api') + '/newAdmin.php?c=Fwnotice&a=delFwNotice',
	getTopData: (isProd ? '' : '/api') + '/newAdmin.php?c=Fwnotice&a=push_info',
}

/*白名单列表*/
export function getWhiteList(parameter) {
	return request({
		url: noticeApi.getWhiteList,
		method: 'post',
		data: parameter
	})
}

/*白名单二维码key*/
export function getKey(parameter) {
	return request({
		url: noticeApi.getKey,
		method: 'post',
		data: parameter
	})
}
/*白名单删除*/
export function delName(parameter) {
	return request({
		url: noticeApi.delName,
		method: 'post',
		data: parameter
	})
}



/*停水类型*/
export function getTsNoticeType(parameter) {
	return request({
		url: noticeApi.getTsNoticeType,
		method: 'post',
		data: parameter
	})
}

/*添加通知*/
export function addNotice(parameter) {
	return request({
		url: noticeApi.addNotice,
		method: 'post',
		data: parameter
	})
}

/*通知列表*/
export function getTsList(parameter) {
	return request({
		url: noticeApi.getTsList,
		method: 'post',
		data: parameter
	})
}

/*删除通知*/
export function delNotice(parameter) {
	return request({
		url: noticeApi.delNotice,
		method: 'post',
		data: parameter
	})
}

/*推送*/
export function sendMsg(parameter) {
	return request({
		url: noticeApi.sendMsg,
		method: 'post',
		data: parameter
	})
}

/*统计*/
export function getData(parameter) {
	return request({
		url: noticeApi.getData,
		method: 'post',
		data: parameter
	})
}

//服务通知列表 
export function getServiceList(parameter) {
	return request({
		url: noticeApi.getServiceList,
		method: 'post',
		data: parameter
	})
}
//服务通知新增编辑 
export function addServiceNotice(parameter) {
	return request({
		url: noticeApi.addServiceNotice,
		method: 'post',
		data: parameter
	})
}


//服务推送
export function sendServiceMsg(parameter) {
	return request({
		url: noticeApi.sendServiceMsg,
		method: 'post',
		data: parameter
	})
}

//服务删除
export function delSend(parameter) {
	return request({
		url: noticeApi.delSend,
		method: 'post',
		data: parameter
	})
}


//服务推送数据
export function getTopData(parameter) {
	return request({
		url: noticeApi.getTopData,
		method: 'post',
		data: parameter
	})
}